// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

function DashboardProductEditorAssetsTab(props) {
  return JsxRuntime.jsxs(Container.make, {
              className: css.addAssetsContainer,
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.formTitle,
                      children: "Add Images & Videos"
                    }),
                props.children
              ]
            });
}

var make = DashboardProductEditorAssetsTab;

export {
  css ,
  make ,
}
/* css Not a pure module */
