// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var Loader = {};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              assetId: field.required("asset_id", Json_Decode$JsonCombinators.string),
              batchId: field.required("batchId", Json_Decode$JsonCombinators.string),
              bytes: field.required("bytes", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("created_at", Json_Decode$JsonCombinators.string),
              etag: field.required("etag", Json_Decode$JsonCombinators.string),
              folder: field.required("folder", Json_Decode$JsonCombinators.string),
              format: field.required("format", Json_Decode$JsonCombinators.string),
              height: field.required("height", Json_Decode$JsonCombinators.$$int),
              id: field.required("id", Json_Decode$JsonCombinators.string),
              originalFilename: field.required("original_filename", Json_Decode$JsonCombinators.string),
              path: field.required("path", Json_Decode$JsonCombinators.string),
              placeholder: field.required("placeholder", Json_Decode$JsonCombinators.bool),
              publicId: field.required("public_id", Json_Decode$JsonCombinators.string),
              resourceType: field.required("resource_type", Json_Decode$JsonCombinators.string),
              secureUrl: field.required("secure_url", Json_Decode$JsonCombinators.string),
              signature: field.required("signature", Json_Decode$JsonCombinators.string),
              tags: field.required("tags", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              thumbnailUrl: field.required("thumbnail_url", Json_Decode$JsonCombinators.string),
              type_: field.required("type", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              version: field.required("version", Json_Decode$JsonCombinators.$$int),
              versionId: field.required("version_id", Json_Decode$JsonCombinators.string),
              width: field.required("width", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Info = {
  decoder: decoder,
  fromJson: fromJson
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              event: field.required("event", Json_Decode$JsonCombinators.string),
              info: field.required("info", decoder)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

export {
  Loader ,
  Info ,
  decoder$1 as decoder,
  fromJson$1 as fromJson,
}
/* decoder Not a pure module */
