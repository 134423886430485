// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../styleguide/components/Heading/H3.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Video from "../../../models/Video.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Api_Product from "../../../api/products/Api_Product.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as Globals from "Common/globals";
import * as CloudinaryWidget from "../../../bindings/CloudinaryWidget.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";
import * as ReactCloudinaryUploadWidget from "react-cloudinary-upload-widget";
import * as DashboardProductEditorImageForm from "./DashboardProductEditorImageForm.res.js";
import * as DashboardProductEditorVideoForm from "./DashboardProductEditorVideoForm.res.js";
import * as DashboardProductEditorAssetsTabVideoLinkForm from "./DashboardProductEditorAssetsTabVideoLinkForm.res.js";

var css = DashboardProductEditorScss;

function reducer(state, action) {
  switch (action.TAG) {
    case "ToggleDestroyConfirm" :
        var id = action._1;
        if (action._0 === "Image") {
          return {
                  destroyImageConfirm: !state.destroyImageConfirm,
                  destroyVideoConfirm: state.destroyVideoConfirm,
                  currentVideo: state.currentVideo,
                  currentImage: id,
                  timeout: state.timeout,
                  videos: state.videos,
                  newVideos: state.newVideos,
                  savedVideos: state.savedVideos,
                  product: state.product
                };
        } else {
          return {
                  destroyImageConfirm: state.destroyImageConfirm,
                  destroyVideoConfirm: !state.destroyVideoConfirm,
                  currentVideo: id,
                  currentImage: state.currentImage,
                  timeout: state.timeout,
                  videos: state.videos,
                  newVideos: state.newVideos,
                  savedVideos: state.savedVideos,
                  product: state.product
                };
        }
    case "SetTimeout" :
        return {
                destroyImageConfirm: state.destroyImageConfirm,
                destroyVideoConfirm: state.destroyVideoConfirm,
                currentVideo: state.currentVideo,
                currentImage: state.currentImage,
                timeout: action._0,
                videos: state.videos,
                newVideos: state.newVideos,
                savedVideos: state.savedVideos,
                product: state.product
              };
    case "StoreAddedLinkedVideo" :
        return {
                destroyImageConfirm: state.destroyImageConfirm,
                destroyVideoConfirm: state.destroyVideoConfirm,
                currentVideo: state.currentVideo,
                currentImage: state.currentImage,
                timeout: state.timeout,
                videos: state.videos,
                newVideos: state.newVideos,
                savedVideos: state.savedVideos,
                product: action._0
              };
    
  }
}

function DashboardProductEditorAssetsTabUploader(props) {
  var updateProduct = props.updateProduct;
  var product = props.product;
  var videos = Video.normalize(props.videos);
  var initialState_videos = [];
  var initialState_newVideos = {
    index: [],
    dict: undefined
  };
  var initialState_savedVideos = {
    index: videos.index,
    dict: Belt_Map.map(videos.dict, (function (video) {
            return [
                    video,
                    "StandBy"
                  ];
          }))
  };
  var initialState = {
    destroyImageConfirm: false,
    destroyVideoConfirm: false,
    currentVideo: undefined,
    currentImage: undefined,
    timeout: undefined,
    videos: initialState_videos,
    newVideos: initialState_newVideos,
    savedVideos: initialState_savedVideos,
    product: product
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var updateTimeout = function (timeout) {
    var match = state.timeout;
    if (match !== undefined) {
      clearTimeout(Belt_Option.getExn(state.timeout));
      return dispatch({
                  TAG: "SetTimeout",
                  _0: Caml_option.some(timeout)
                });
    } else {
      return dispatch({
                  TAG: "SetTimeout",
                  _0: Caml_option.some(timeout)
                });
    }
  };
  var match$1 = product.images;
  var match$2 = product.videos;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(ReactCloudinaryUploadWidget.WidgetLoader, {}),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      "Click to Upload Images and Videos",
                                      JsxRuntime.jsx(ReactCloudinaryUploadWidget.Widget, {
                                            sources: ["local"],
                                            cloudName: Globals.CloudinaryCloudName,
                                            apiKey: Globals.CloudinaryAPIKey,
                                            uploadPreset: Globals.CloudinaryUploadPreset,
                                            accepts: "application/json",
                                            contentType: "application/json",
                                            folder: "products/" + product.slug + "/images",
                                            style: {
                                              color: "white",
                                              border: "1px solid",
                                              borderColor: "#18c3bb",
                                              borderRadius: "3px",
                                              fontWeight: "600",
                                              backgroundColor: "#18c3bb",
                                              padding: "8px 20px",
                                              fontSize: "14px"
                                            },
                                            cropping: false,
                                            multiple: true,
                                            onSuccess: (function (result) {
                                                console.log("RESULT:", CloudinaryWidget.fromJson(result));
                                                var content = CloudinaryWidget.fromJson(result);
                                                var match = content.info.format;
                                                var exit = 0;
                                                switch (match) {
                                                  case "jpeg" :
                                                  case "jpg" :
                                                  case "png" :
                                                      exit = 2;
                                                      break;
                                                  case "avi" :
                                                  case "mov" :
                                                  case "mp4" :
                                                  case "wmv" :
                                                      exit = 1;
                                                      break;
                                                  default:
                                                    exit = 2;
                                                }
                                                switch (exit) {
                                                  case 1 :
                                                      return $$Promise.wait(Api_Product.createVideo(product.id, {
                                                                      video: content
                                                                    }), (function (x) {
                                                                    if (x.TAG === "Ok") {
                                                                      return updateProduct(x._0);
                                                                    } else {
                                                                      return SentryLogger.error1({
                                                                                  rootModule: "DashboardProductEditorAssetsTabUploader",
                                                                                  subModulePath: /* [] */0,
                                                                                  value: "make",
                                                                                  fullPath: "DashboardProductEditorAssetsTabUploader.make"
                                                                                }, "CreateProductVideo::Error", [
                                                                                  "Error",
                                                                                  x._0
                                                                                ]);
                                                                    }
                                                                  }));
                                                  case 2 :
                                                      return $$Promise.wait(Api_Product.createImage(product.id, {
                                                                      image: content
                                                                    }), (function (x) {
                                                                    if (x.TAG === "Ok") {
                                                                      return updateProduct(x._0);
                                                                    } else {
                                                                      return SentryLogger.error1({
                                                                                  rootModule: "DashboardProductEditorAssetsTabUploader",
                                                                                  subModulePath: /* [] */0,
                                                                                  value: "make",
                                                                                  fullPath: "DashboardProductEditorAssetsTabUploader.make"
                                                                                }, "CreateProductImage::Error", [
                                                                                  "Error",
                                                                                  x._0
                                                                                ]);
                                                                    }
                                                                  }));
                                                  
                                                }
                                              })
                                          })
                                    ],
                                    className: css.uploaderInner
                                  })
                            ],
                            className: css.uploader
                          }),
                      className: css.uploaderContainer
                    }),
                JsxRuntime.jsx(DashboardProductEditorAssetsTabVideoLinkForm.make, {
                      productId: product.id,
                      videos: state.videos,
                      addLinkedVideo: (function (product) {
                          dispatch({
                                TAG: "StoreAddedLinkedVideo",
                                _0: product
                              });
                        }),
                      updateProduct: updateProduct
                    }),
                match$1.length !== 0 ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(H3.make, {
                                children: "Images"
                              }),
                          JsxRuntime.jsx("div", {
                                children: Belt_Array.mapWithIndex(product.images, (function (index, image) {
                                        return JsxRuntime.jsx(DashboardProductEditorImageForm.make, {
                                                    product: product,
                                                    image: image,
                                                    updateProduct: updateProduct,
                                                    toggleDestroy: (function (id) {
                                                        dispatch({
                                                              TAG: "ToggleDestroyConfirm",
                                                              _0: "Image",
                                                              _1: Caml_option.some(id)
                                                            });
                                                      }),
                                                    updateTimeout: (function (timeout) {
                                                        updateTimeout(timeout);
                                                      })
                                                  }, "image--" + String(index) + "--" + ID.toString(image.id));
                                      })),
                                className: css.imagesGrid
                              })
                        ],
                        className: css.imagesContainer
                      }) : null,
                match$2.length !== 0 ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(H3.make, {
                                children: "Videos"
                              }),
                          JsxRuntime.jsx("div", {
                                children: Belt_Array.mapWithIndex(product.videos, (function (index, video) {
                                        return JsxRuntime.jsx(DashboardProductEditorVideoForm.make, {
                                                    product: product,
                                                    video: video,
                                                    updateProduct: updateProduct,
                                                    toggleDestroy: (function (id) {
                                                        dispatch({
                                                              TAG: "ToggleDestroyConfirm",
                                                              _0: "Video",
                                                              _1: Caml_option.some(id)
                                                            });
                                                      }),
                                                    updateTimeout: (function (timeout) {
                                                        updateTimeout(timeout);
                                                      })
                                                  }, "video--" + String(index) + "--" + ID.toString(video.id));
                                      })),
                                className: css.videosGrid
                              })
                        ],
                        className: css.videosContainer
                      }) : null,
                state.destroyImageConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: css.deleteConfirmationText
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Image will be deleted permanently.",
                                      className: css.deleteConfirmationMessage
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleDestroyConfirm",
                                                        _0: "Image",
                                                        _1: undefined
                                                      });
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var productId = product.id;
                                                  var imageId = Belt_Option.getExn(state.currentImage);
                                                  $$Promise.wait(Api_Product.deleteImage(productId, imageId), (function (x) {
                                                          if (x.TAG !== "Ok") {
                                                            return SentryLogger.error1({
                                                                        rootModule: "DashboardProductEditorAssetsTabUploader",
                                                                        subModulePath: /* [] */0,
                                                                        value: "make",
                                                                        fullPath: "DashboardProductEditorAssetsTabUploader.make"
                                                                      }, "DeleteProductImage::Error", [
                                                                        "Error",
                                                                        x._0
                                                                      ]);
                                                          }
                                                          updateProduct(x._0);
                                                          dispatch({
                                                                TAG: "ToggleDestroyConfirm",
                                                                _0: "Image",
                                                                _1: undefined
                                                              });
                                                        }));
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: css.deleteConfirmationButtons
                                    })
                              ],
                              className: css.deleteConfirmationDialog
                            }),
                        className: css.deleteConfirmationOverlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null,
                state.destroyVideoConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: css.deleteConfirmationText
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Image will be deleted permanently.",
                                      className: css.deleteConfirmationMessage
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleDestroyConfirm",
                                                        _0: "Video",
                                                        _1: undefined
                                                      });
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var productId = product.id;
                                                  var videoId = Belt_Option.getExn(state.currentVideo);
                                                  $$Promise.wait(Api_Product.deleteVideo(productId, videoId), (function (x) {
                                                          if (x.TAG !== "Ok") {
                                                            return SentryLogger.error1({
                                                                        rootModule: "DashboardProductEditorAssetsTabUploader",
                                                                        subModulePath: /* [] */0,
                                                                        value: "make",
                                                                        fullPath: "DashboardProductEditorAssetsTabUploader.make"
                                                                      }, "DeleteProductVideo::Error", [
                                                                        "Error",
                                                                        x._0
                                                                      ]);
                                                          }
                                                          updateProduct(x._0);
                                                          dispatch({
                                                                TAG: "ToggleDestroyConfirm",
                                                                _0: "Video",
                                                                _1: undefined
                                                              });
                                                        }));
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: css.deleteConfirmationButtons
                                    })
                              ],
                              className: css.deleteConfirmationDialog
                            }),
                        className: css.deleteConfirmationOverlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null
              ],
              className: css.container
            });
}

var make = DashboardProductEditorAssetsTabUploader;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
