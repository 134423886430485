// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(video) {
  return Json_Encode$JsonCombinators.object([
              [
                "description",
                video.description
              ],
              [
                "active",
                video.active
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(productId, videoId, video) {
  return Rest.$$fetch("/dashboard/products/" + (ID.toString(productId) + ("/videos/" + ID.toString(videoId))), {
              NAME: "Patch",
              VAL: toJson(video)
            }, {
              NAME: "Json",
              VAL: ColocationProduct.Draft.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
