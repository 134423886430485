// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../styleguide/icons/IconMenu.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

var initialState = {
  status: "StandBy",
  error: false
};

function DashboardProductEditorContextMenu(props) {
  var onUpdated = props.onUpdated;
  var product = props.product;
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (action !== "Archive") {
            return {
                    TAG: "Update",
                    _0: {
                      status: "StandBy",
                      error: true
                    }
                  };
          }
          var match = state.status;
          if (match === "StandBy") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      status: "Archiving",
                      error: state.error
                    },
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        $$Promise.wait(Api.archiveProduct(product.id), (function (x) {
                                if (x.TAG === "Ok") {
                                  var newrecord = Caml_obj.obj_dup(product);
                                  return onUpdated((newrecord.status = "Archived", newrecord));
                                }
                                SentryLogger.error1({
                                      rootModule: "DashboardProductEditorContextMenu",
                                      subModulePath: /* [] */0,
                                      value: "make",
                                      fullPath: "DashboardProductEditorContextMenu.make"
                                    }, "DashboardProductEditorContextMenu" + "::Archive::Error", [
                                      "Error",
                                      x._0
                                    ]);
                                dispatch("Fail");
                              }));
                      })
                  };
          } else {
            return "NoUpdate";
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.status;
  var tmp;
  tmp = match$1 === "StandBy" ? "Archive" : "Archiving...";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Popover.make, {
                      children: [
                        JsxRuntime.jsx(Popover.Trigger.make, {
                              children: JsxRuntime.jsx(Control.make, {
                                    children: JsxRuntime.jsx(IconMenu.make, {
                                          size: "LG",
                                          color: "Teal"
                                        })
                                  })
                            }),
                        JsxRuntime.jsx(Dropdown.Body.make, {
                              position: {
                                TAG: "Above",
                                _0: "LeftEdge"
                              },
                              children: JsxRuntime.jsx(Control.make, {
                                    onClick: (function (param) {
                                        dispatch("Archive");
                                      }),
                                    children: tmp
                                  })
                            })
                      ]
                    }),
                state.error ? JsxRuntime.jsx("div", {
                        children: "Something went wrong",
                        className: css.actionBarError
                      }) : null
              ]
            });
}

var make = DashboardProductEditorContextMenu;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */
