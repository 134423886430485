// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateProductsImageRequest from "./requests/CreateProductsImageRequest.res.js";
import * as CreateProductsVideoRequest from "./requests/CreateProductsVideoRequest.res.js";
import * as DeleteProductsImageRequest from "./requests/DeleteProductsImageRequest.res.js";
import * as DeleteProductsVideoRequest from "./requests/DeleteProductsVideoRequest.res.js";
import * as UpdateProductsImageRequest from "./requests/UpdateProductsImageRequest.res.js";
import * as UpdateProductsVideoRequest from "./requests/UpdateProductsVideoRequest.res.js";

var createImage = CreateProductsImageRequest.exec;

var createVideo = CreateProductsVideoRequest.exec;

var deleteImage = DeleteProductsImageRequest.exec;

var deleteVideo = DeleteProductsVideoRequest.exec;

var updateImage = UpdateProductsImageRequest.exec;

var updateVideo = UpdateProductsVideoRequest.exec;

export {
  createImage ,
  createVideo ,
  deleteImage ,
  deleteVideo ,
  updateImage ,
  updateVideo ,
}
/* CreateProductsImageRequest Not a pure module */
