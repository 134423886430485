// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as Routes_Product from "../../../routes/common/Routes_Product.res.js";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorHeader from "../../common/dashboard/DashboardProductEditorHeader.res.js";
import * as DashboardProductEditorLayout from "../../common/dashboard/DashboardProductEditorLayout.res.js";
import * as DashboardProductEditorActionBar from "../../common/dashboard/DashboardProductEditorActionBar.res.js";
import * as DashboardProductEditorAssetsTab from "../../common/dashboard/DashboardProductEditorAssetsTab.res.js";
import * as DashboardProductEditorContextMenu from "../../common/dashboard/DashboardProductEditorContextMenu.res.js";
import * as DashboardProductEditorUnarchiveLink from "../../common/dashboard/DashboardProductEditorUnarchiveLink.res.js";
import * as DashboardProductEditorAssetsTabUploader from "../../common/dashboard/DashboardProductEditorAssetsTabUploader.res.js";
import * as DashboardProductEditorActiveStatusToggle from "../../common/dashboard/DashboardProductEditorActiveStatusToggle.res.js";

function DashboardEditProductAssetsTab$DashboardEditProductAssetsTab(props) {
  var product = props.product;
  var initialState = React.useMemo((function () {
          return {
                  product: product,
                  status: "Editing",
                  submissionError: false
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            return {
                    TAG: "Update",
                    _0: {
                      product: action._0,
                      status: state.status,
                      submissionError: state.submissionError
                    }
                  };
          }
          switch (action) {
            case "PublishProduct" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          product: state.product,
                          status: "Submitting",
                          submissionError: state.submissionError
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            $$Promise.wait(Api.publishProduct(product.id), (function (x) {
                                    if (x.TAG === "Ok") {
                                      return Url.visit(Routes_Product.Dashboard.index);
                                    }
                                    SentryLogger.error1({
                                          rootModule: "DashboardEditProductAssetsTab",
                                          subModulePath: {
                                            hd: "DashboardEditProductAssetsTab",
                                            tl: /* [] */0
                                          },
                                          value: "make",
                                          fullPath: "DashboardEditProductAssetsTab.DashboardEditProductAssetsTab.make"
                                        }, "DashboardEditProductAssetsTab::PublishProduct::Error", [
                                          "Error",
                                          x._0
                                        ]);
                                    dispatch("FailProductPublishing");
                                  }));
                          })
                      };
            case "ActivateProduct" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          product: state.product,
                          status: "Submitting",
                          submissionError: state.submissionError
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            $$Promise.wait(Api.activateProduct(product.id), (function (x) {
                                    if (x.TAG === "Ok") {
                                      return Url.visit(Routes_Product.Dashboard.index);
                                    }
                                    SentryLogger.error1({
                                          rootModule: "DashboardEditProductAssetsTab",
                                          subModulePath: {
                                            hd: "DashboardEditProductAssetsTab",
                                            tl: /* [] */0
                                          },
                                          value: "make",
                                          fullPath: "DashboardEditProductAssetsTab.DashboardEditProductAssetsTab.make"
                                        }, "DashboardEditProductAssetsTab::ActivateProduct::Error", [
                                          "Error",
                                          x._0
                                        ]);
                                    dispatch("FailProductActivation");
                                  }));
                          })
                      };
            case "FailProductPublishing" :
            case "FailProductActivation" :
                return {
                        TAG: "Update",
                        _0: {
                          product: state.product,
                          status: "Editing",
                          submissionError: true
                        }
                      };
            case "GoToPreviousTab" :
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            Url.visit(Routes_Product.Dashboard.editLocation(product.id));
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.product.status;
  var tmp;
  var exit = 0;
  switch (match$1) {
    case "Active" :
        var match$2 = state.status;
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DashboardProductEditorActiveStatusToggle.make, {
                      productId: product.id,
                      status: "Active",
                      busy: match$2 !== "Editing"
                    }),
                JsxRuntime.jsx(DashboardProductEditorContextMenu.make, {
                      product: state.product,
                      onUpdated: (function (product) {
                          dispatch({
                                TAG: "UpdateProduct",
                                _0: product
                              });
                        })
                    })
              ]
            });
        break;
    case "Disabled" :
        var match$3 = state.status;
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DashboardProductEditorActiveStatusToggle.make, {
                      productId: product.id,
                      status: "Disabled",
                      busy: match$3 !== "Editing"
                    }),
                JsxRuntime.jsx(DashboardProductEditorContextMenu.make, {
                      product: state.product,
                      onUpdated: (function (product) {
                          dispatch({
                                TAG: "UpdateProduct",
                                _0: product
                              });
                        })
                    })
              ]
            });
        break;
    case "Draft" :
    case "Expired" :
        exit = 1;
        break;
    case "Archived" :
        tmp = JsxRuntime.jsx(DashboardProductEditorUnarchiveLink.make, {
              productId: product.id,
              onUnarchived: (function (product) {
                  dispatch({
                        TAG: "UpdateProduct",
                        _0: product
                      });
                })
            });
        break;
    
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(DashboardProductEditorContextMenu.make, {
          product: state.product,
          onUpdated: (function (product) {
              dispatch({
                    TAG: "UpdateProduct",
                    _0: product
                  });
            })
        });
  }
  var match$4 = state.product.status;
  var tmp$1;
  switch (match$4) {
    case "Active" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DashboardProductEditorActionBar.Note.make, {
                      children: "Changes on this page are being published automatically"
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "LG",
                      color: "Primary",
                      expanded: true,
                      visuallyDisabled: true,
                      children: "Save changes"
                    })
              ]
            });
        break;
    case "Draft" :
        var match$5 = state.status;
        var match$6 = state.status;
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DashboardProductEditorActionBar.$$Error.make, {
                      children: state.submissionError ? "Something went wrong" : null
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "LG",
                      color: "Primary",
                      expanded: true,
                      disabled: match$5 !== "Editing",
                      busy: match$6 !== "Editing",
                      onClick: (function (param) {
                          dispatch("PublishProduct");
                        }),
                      children: "Publish product"
                    })
              ]
            });
        break;
    case "Disabled" :
        var match$7 = state.status;
        var match$8 = state.status;
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DashboardProductEditorActionBar.$$Error.make, {
                      children: state.submissionError ? "Something went wrong" : null
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "LG",
                      color: "Primary",
                      expanded: true,
                      disabled: match$7 !== "Editing",
                      busy: match$8 !== "Editing",
                      onClick: (function (param) {
                          dispatch("ActivateProduct");
                        }),
                      children: "Activate product"
                    })
              ]
            });
        break;
    case "Expired" :
    case "Archived" :
        tmp$1 = null;
        break;
    
  }
  return JsxRuntime.jsxs(DashboardProductEditorLayout.make, {
              children: [
                JsxRuntime.jsx(DashboardProductEditorHeader.make, {
                      product: product,
                      active: "AssetsTab"
                    }),
                JsxRuntime.jsxs(DashboardProductEditorAssetsTab.make, {
                      children: [
                        JsxRuntime.jsx(DashboardProductEditorAssetsTabUploader.make, {
                              product: state.product,
                              updateProduct: (function (product) {
                                  dispatch({
                                        TAG: "UpdateProduct",
                                        _0: product
                                      });
                                }),
                              videos: state.product.videos
                            }),
                        JsxRuntime.jsxs(DashboardProductEditorActionBar.make, {
                              children: [
                                JsxRuntime.jsx(DashboardProductEditorActionBar.ContextControls.make, {
                                      children: tmp
                                    }),
                                tmp$1
                              ]
                            })
                      ]
                    })
              ]
            });
}

var DashboardEditProductAssetsTab = {
  make: DashboardEditProductAssetsTab$DashboardEditProductAssetsTab
};

function DashboardEditProductAssetsTab$default(props) {
  return JsxRuntime.jsx(DashboardEditProductAssetsTab$DashboardEditProductAssetsTab, {
              product: ColocationProduct.Draft.fromJs(props.props.product)
            });
}

var $$default = DashboardEditProductAssetsTab$default;

export {
  DashboardEditProductAssetsTab ,
  $$default as default,
}
/* Api Not a pure module */
