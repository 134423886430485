// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Toggle from "../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

function DashboardProductEditorActiveStatusToggle(props) {
  var busy = props.busy;
  var status = props.status;
  var productId = props.productId;
  var initialState = React.useMemo((function () {
          return {
                  status: {
                    TAG: "StandBy",
                    _0: status
                  },
                  error: false
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            if (action.TAG === "Succeed") {
              return {
                      TAG: "Update",
                      _0: {
                        status: {
                          TAG: "StandBy",
                          _0: action._0
                        },
                        error: false
                      }
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: {
                          TAG: "StandBy",
                          _0: action._0
                        },
                        error: true
                      }
                    };
            }
          }
          var prevStatus = state.status;
          if (busy) {
            return "NoUpdate";
          }
          if (prevStatus.TAG === "Submitting") {
            return "NoUpdate";
          }
          var prevStatus$1 = prevStatus._0;
          if (prevStatus$1 === "Disabled") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      status: {
                        TAG: "Submitting",
                        _0: "Active"
                      },
                      error: false
                    },
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        $$Promise.wait(Api.activateProduct(productId), (function (x) {
                                if (x.TAG === "Ok") {
                                  return dispatch({
                                              TAG: "Succeed",
                                              _0: "Active"
                                            });
                                } else {
                                  return dispatch({
                                              TAG: "Fail",
                                              _0: prevStatus$1
                                            });
                                }
                              }));
                      })
                  };
          } else {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      status: {
                        TAG: "Submitting",
                        _0: "Disabled"
                      },
                      error: false
                    },
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        $$Promise.wait(Api.deactivateProduct(productId), (function (x) {
                                if (x.TAG === "Ok") {
                                  return dispatch({
                                              TAG: "Succeed",
                                              _0: "Disabled"
                                            });
                                } else {
                                  return dispatch({
                                              TAG: "Fail",
                                              _0: prevStatus$1
                                            });
                                }
                              }));
                      })
                  };
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.status;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Toggle.make, {
                      on: match$1._0 !== "Disabled",
                      size: "LG",
                      onChange: (function () {
                          dispatch("Submit");
                        })
                    }),
                state.error ? JsxRuntime.jsx("div", {
                        children: "Something went wrong",
                        className: css.actionBarError
                      }) : null
              ]
            });
}

var make = DashboardProductEditorActiveStatusToggle;

export {
  css ,
  make ,
}
/* css Not a pure module */
